import React from "react";

const About = () => {
    return (
        <section id="whychooseus">
            <div className="container" data-aos="fade-up">
                <header className="section-header">
                    <h3>De ce să ne alegeți</h3>
                    <p>

                    </p>
                </header>
                <div className="row about-cols">
                    <div className="col-md-4" data-aos="fade-up" data-aos-delay={200}>
                        <div className="about-col">
                            <div className="img">
                                <img
                                    src=""
                                    alt=""
                                    className="img-fluid"
                                />
                                <div className="icon">
                                    <i className="bi bi-person-lines-fill" />
                                </div>
                            </div>
                            <h2 className="title">
                                <a href="#about">Tehnologii de prototipare rapidă</a>
                            </h2>
                            <br />
                            <p>
                                Creăm soluții ajustate nevoilor tale în diverse domenii, începând de la industria petrolieră până la industria farmaceutică, industria prelucrătoare, construcții de mașini, confecții metalice, prefabricate, industria energetică, medicină și altele.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4" data-aos="fade-up" data-aos-delay={100}>
                        <div className="about-col">
                            <div className="img">
                                <img
                                    src=""
                                    alt=""
                                    className="img-fluid"
                                />
                                <div className="icon">
                                    <i className="bi bi-pass" />
                                </div>
                            </div>
                            <h2 className="title">
                                <a href="#about">Experiență interdisciplinară</a>
                            </h2>
                            <br />
                            <p>
                                Implementăm cu ușurintă soluții noi de înlocuire a
                                componentelor care nu se mai comercializează; restaurăm echipamente vechi, care pot atinge
                                potențialul lor maxim prin înlocuirea comenzilor manuale sau logică secvențială cu
                                calculatoare noi și softuri dedicate, crescând astfel eficiența și siguranța în exploatare
                            </p>
                        </div>
                    </div>

                    <div className="col-md-4" data-aos="fade-up" data-aos-delay={100}>
                        <div className="about-col">
                            <div className="img">
                                <img
                                    src=""
                                    alt=""
                                    className="img-fluid"
                                />
                                <div className="icon">
                                    <i className="bi bi-patch-check" />
                                </div>
                            </div>
                            <h2 className="title">
                                <a href="#about">Apreciem lucrul bine făcut!</a>
                            </h2>
                            <br />
                            <p>
                                Prin urmare lucrăm doar cu furnizori care oferă calitate înaltă:
                            </p>
                            <p>
                                EATON, Pepperl+Fuchs, Endress+Hauser, Turck, Omron, HYDAC, SMC
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </section>
    );
};

export default About;
