import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { heroImages } from "../data/heroImages";
import 'animate.css';

const Hero = () => {

  return (
    <section id="hero">
      <div className="hero-container">
        <Carousel fade pause={false}>
          {heroImages.map((heroImage, i) =>
            <Carousel.Item key={(heroImage, i)} style={{ backgroundImage: `url(${heroImage.img})` }} interval={2500}></Carousel.Item>
          )}
        </Carousel>
        <div className='carousel-container'>
          <div className="container">
            <h2 className="animate__animated animate__fadeInDown">
              TRY Innovation
            </h2>
            <p className="animate__animated animate__fadeInUp">
              Oferim soluții integrate de automatizare și eficientizare a producției, sprijinind astfel beneficiarii, prin reducerea costurilor și
              optimizarea randamentului.
            </p>
            <a
              href="#contact"
              className="btn-get-started scrollto animate__animated animate__fadeInUp"
            >
              Contactează-ne
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
