import c1 from "../img/clients/client-1.jpeg";


export const clients = [
    {
        img: c1,
        alt: "",
        href:"https://www.rodbun.ro",
        arialabel: "Rodbun"
    },
    
];