import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const form = useRef();
  const [showConfirm, setShowConfirm] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const sendEmail = (e) => {
    setShowLoading(true);
    e.preventDefault();
    emailjs.sendForm('service_xx55u5e', 'template_efof0i9', form.current, 'tsjlqR9VClGfoOD5y')
      .then((result) => {
        console.log(result.text);
        e.target.reset();
        return setShowConfirm(true);
      }, (error) => {
        console.log(error.text);
        e.target.reset();
        return setShowError(true);
      });
  };
  return (
    <section id="contact" className="section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h3>Contactează-ne</h3>
          <p>
            Te rugăm să ne contactezi și iți oferim toate informațiile de care
            ai nevoie!
          </p>
        </div>
        <div className="row contact-info">
          <div className="col-md-3">
            <div className="contact-address">
              <i className="bi bi-geo-alt" />
              <h3>Adresă</h3>
              <a href="https://www.google.com/maps/search/?api=1&query=TRY%20Innovation%20Automatizari%20industriale%2C%20Targu-Mures">Strada Libertății, nr. 120, incinta ITA, Târgu-Mureș, România</a>
            </div>
          </div>
          <div className="col-md-3">
            <div className="contact-phone">
              <i className="bi bi-phone" />
              <h3>Telefon</h3>
              <p>
                <a href="tel:40745763640">+40745763640</a>
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="contact-whatsapp">
              <i className="bi bi-whatsapp" />
              <h3>Whatsapp</h3>
              <p>
                <a href="https://wa.me/40745763640">+40745763640</a>
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="contact-email">
              <i className="bi bi-envelope" />
              <h3>Email</h3>
              <p>
                <a href="mailto:pcosma@tryinnovation.ro">pcosma@tryinnovation.ro</a>
              </p>
            </div>
          </div>
        </div>
        <div className="form">
          <form ref={form} onSubmit={sendEmail}
            className="form"
          >
            <div className="row">
              <div className="form-group col-md-6">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  id="name"
                  placeholder="Nume"
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  id="email"
                  placeholder="Email"
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <textarea
                className="form-control"
                name="message"
                rows={5}
                placeholder="Mesaj"
                required
                defaultValue={""}
              />
            </div>
            {showLoading && !showConfirm && !showError && <div className="my-3">
              <div className="loading">Se încarcă</div>
            </div>
            }
            {showError && <div className="my-3">
              <div className="error-message text-center">Mesajul nu a putut fi trimis.</div>
            </div>
            }
            {showConfirm && <div className="my-3">
              <div className="sent-message">
                Mesajul tău a fost trimis. Mulțumim!
              </div>
            </div>
            }
            <div className="text-center">
              <button type="submit">Trimite</button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
