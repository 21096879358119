import React from "react";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-md-6 footer-links">
              <h4>Link-uri</h4>
              <ul>
                <li>
                  <i className="bi bi-chevron-right" /> <a href="#hero">Acasă</a>
                </li>
                <li>
                  <i className="bi bi-chevron-right" />{" "}
                  <a href="#about">Despre noi</a>
                </li>
                <li>
                  <i className="bi bi-chevron-right" />{" "}
                  <a href="#services">Servicii</a>
                </li>
                <li>
                  <i className="bi bi-chevron-right" />{" "}
                  <a href="#portfolio">Portofoliu</a>
                </li>
                <li>
                  <i className="bi bi-chevron-right" />{" "}
                  <a href="/">Politica de confidențialitate</a>
                </li>
              </ul>
            </div>
            <div className="col-md-6 footer-contact">
              <h4>Contact</h4>
              <p>
                <strong>Adresa: </strong>
                <br />
                Strada Libertății, nr. 120, incinta ITA <br />
                Târgu-Mureș, România <br />
                <strong>Telefon:</strong> +40745763640
                <br />
                <strong>Email:</strong> pcosma@tryinnovation.ro
                <br />
              </p>
              <div className="social-links">
                <a
                  href="https://www.facebook.com/tryinnovation"
                  className="facebook"
                  aria-label="Try Innovation Facebook"
                >
                  <i className="bi bi-facebook" />
                </a>
                <a href="https://www.linkedin.com/company/try-innovation/" className="linkedin" aria-label="Try Innovation Linkedin">
                  <i className="bi bi-linkedin" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          © Copyright <strong>Try Innovation</strong>. All Rights Reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
