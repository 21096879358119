import React, { Component } from 'react'
import { Navigation, Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default class MoreInfo extends Component {
    render() {
        let modelStyle = {
            display: 'block',
            backgroundColor: 'rgba( 0,0,0,0.94)',
        }
        return (
            <div className="modal show fade" style={modelStyle} >
                <div className="modal-dialog">
                    <div className="modal-content">
                        < button type="button" className="closeButton" onClick={this.props.hide}></button >
                        <div className="modal-body">
                            <Swiper
                                modules={[Autoplay, Navigation, Pagination]}
                                speed={800}
                                loop={true}
                                autoplay={{ delay: 1500, disableOnInteraction: false }}
                                slidesPerView={1}
                                navigation
                                pagination={{ clickable: true }}
                            >
                                <SwiperSlide className="modal-slide">
                                    <img src={this.props.img1} className="img-fluid" alt="" />
                                </SwiperSlide>
                                <SwiperSlide className="modal-slide">
                                    <img src={this.props.img2} className="img-fluid" alt="" />
                                </SwiperSlide>
                                {this.props.img3 && <SwiperSlide className="modal-slide">
                                    <img src={this.props.img3} className="img-fluid" alt="" />
                                </SwiperSlide>}
                                {this.props.img4 && <SwiperSlide className="modal-slide">
                                    <img src={this.props.img4} className="img-fluid" alt="" />
                                </SwiperSlide>}
                                {this.props.img5 && <SwiperSlide className="modal-slide">
                                    <img src={this.props.img5} className="img-fluid" alt="" />
                                </SwiperSlide>}
                                {this.props.img6 && <SwiperSlide className="modal-slide">
                                    <img src={this.props.img6} className="img-fluid" alt="" />
                                </SwiperSlide>}
                            </Swiper>
                            <h4>{this.props.title}</h4>
                            <p>{this.props.description}</p>
                        </div >
                    </ div >
                </ div >
            </ div >
        )
    }
}