import React from "react";
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import { clients } from "../data/clients";

const Clients = () => {
  return (
    <section id="clients">
      <div className="container" data-aos="zoom-in">
        <header className="section-header">
          <h3>Clienții noștri</h3>
        </header>
        <Swiper
          modules={[Autoplay]}
          speed={600}
          loop={true}
          autoplay={{ delay: 7500, disableOnInteraction: false }}
          slidesPerView={1}
        >
          {clients.map((client, i) =>
            <SwiperSlide key={(client, i)}>
              <a href={client.href} aria-label={client.arialabel}>
                <img
                  src={client.img}
                  className="img-fluid"
                  alt=""
                />
              </a>

            </SwiperSlide>
          )}
        </Swiper>
      </div>
      <br />
    </section>
  );
};

export default Clients;
