import React, { useState } from "react";
import l1 from "../img/logo-orange.png"

const Header = () => {
  const [animate, setAnimate] = useState(false);
  const [show, setShow] = useState(false);

  const toggle = () => {
    setShow(!show);
    console.log(show);
  };

  const onscroll = (el, listener) => {
    el.addEventListener('scroll', listener)
  }
  const select = (el, all = false) => {
    el = el.trim()
    if (all) {
      return [...document.querySelectorAll(el)]
    } else {
      return document.querySelector(el)
    }
  }
  let navbarlinks = select('#navbar .scrollto', true)

  const navbarlinksActive = () => {
    let position = window.scrollY + 200
    navbarlinks.forEach(navbarlink => {
      if (!navbarlink.hash) return
      let section = select(navbarlink.hash)
      if (!section) return
      if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
        navbarlink.classList.add('active')
      } else {
        navbarlink.classList.remove('active')
      }
    })
  }
  window.addEventListener('load', navbarlinksActive)
  onscroll(document, navbarlinksActive)

  const animateNavbar = () => {
    if (window.scrollY >= (window.innerHeight / 1.2)) {
      setAnimate(true);
    } else {
      setAnimate(false);
    }
  };
  window.addEventListener("scroll", animateNavbar);

  const scrollto = (el) => {
    let header = select('#header')
    let offset = header.offsetHeight

    if (!header.classList.contains('header-scrolled')) {
      offset -= 20
    }

    let elementPos = select(el).offsetTop
    window.scrollTo({
      top: elementPos - offset,
      behavior: 'smooth'
    })
  }

  const on = (type, el, listener, all = false) => {
    let selectEl = select(el, all)
    if (selectEl) {
      if (all) {
        selectEl.forEach(e => e.addEventListener(type, listener))
      } else {
        selectEl.addEventListener(type, listener)
      }
    }
  }

  on('click', '.scrollto', function (e) {
    if (select(this.hash)) {
      e.preventDefault()

      let navbar = select('#navbar')
      if (navbar.classList.contains('navbar-mobile')) {
        navbar.classList.remove('navbar-mobile')
        let navbarToggle = select('.mobile-nav-toggle')
        navbarToggle.classList.toggle('bi-list')
        navbarToggle.classList.toggle('bi-x')
      }
      scrollto(this.hash)
    }
  }, true)

  return (
    <header
      id="header"
      className={`fixed-top d-flex align-items-center ${animate ? "header-scrolled" : "header-transparent"}`}
    >
      <div className="container-fluid">
        <div className="row justify-content-center align-items-center">
          <div className="col-xl-11 d-flex align-items-center justify-content-between">
            <h1 className="logo">
              <a href="/" aria-label="Try Innovation">
                <img
                  src={l1}
                  className="img"
                  alt=""
                />
              </a>
            </h1>
            <nav id="navbar" className={`navbar ${show ? "navbar-mobile" : ""}`}>
              <ul>
                <li>
                  <a className="nav-link scrollto active" href="#hero">
                    Acasă
                  </a>
                </li>
                <li>
                  <a className="nav-link scrollto" href="#about">
                    Despre noi
                  </a>
                </li>
                <li>
                  <a className="nav-link scrollto" href="#services">
                    Servicii
                  </a>
                </li>
                <li>
                  <a className="nav-link scrollto" href="#whychooseus">
                    De ce să ne alegeți
                  </a>
                </li>
                <li>
                  <a className="nav-link scrollto " href="#portfolio">
                    Portofoliu
                  </a>
                </li>
                <li>
                  <a className="nav-link scrollto" href="#contact">
                    Contact
                  </a>
                </li>
              </ul>
              <i onClick={toggle} className={`bi ${show ? "bi-x" : "bi-list"} mobile-nav-toggle`} />
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
