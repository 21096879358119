import React from "react";

const CallToAction = () => {
  return (
    <section id="call-to-action">
      <br />
      <div className="container text-center" data-aos="zoom-in">
        <h3>Contactează-ne</h3>
        <p>
          {" "}
          Pentru mai multe informatii, nu ezita sa ne contactezi si iti vom
          raspunde la toate intrebarile!
        </p>
        <a className="cta-btn" href="#contact">
          Contact
        </a>
      </div>
      <br />
    </section>
  );
};

export default CallToAction;
