import React from "react";
import About from "../components/About";
import BackToTop from "../components/BackToTop";
import CallToAction from "../components/CallToAction";
import Clients from "../components/Clients";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Portfolio from "../components/Portfolio";
import Services from "../components/Services";
import WhyChooseUs from "../components/WhyChooseUs";
// import Testimonials from "../components/Testimonials";
import Facts from "../components/Facts";

const Home = () => {
  return (
    <>
      <Header />
      <Hero />
      <BackToTop />
      <About />
      <Services />
      <WhyChooseUs />
      <CallToAction />
      <Facts />
      <Portfolio />
      <Clients />
      {/* <Testimonials /> */}
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
