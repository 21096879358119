import React from "react";
import { useCountUp } from 'react-countup';

const Facts = () => {
    useCountUp({
        ref: 'counter1',
        start: 0,
        end: 373,
        duration: 1.5,
        enableScrollSpy: true
    })
    useCountUp({
        ref: 'counter2',
        start: 0,
        end: 280,
        duration: 1.5,
        enableScrollSpy: true
    })
    useCountUp({
        ref: 'counter3',
        start: 0,
        end: 100,
        duration: 1.5,
        enableScrollSpy: true
    })

    return (
        <section id="facts">
            <div className="container" data-aos="fade-up">
                <header className="section-header">
                    <h3>Realizări</h3>
                    <p>Obiectivul nostru principal este satisfacerea nevoilor și așteptărilor clienților și ne bucurăm enorm să vedem că majoritatea clienților noștri sunt recurenți.</p>
                </header>
                <div className="row counters" data-aos="fade-up" data-aos-delay={100}>
                    <div className="col-lg-4 text-center">
                        <span id="counter1" />
                        <p>Proiecte</p>
                    </div>
                    <div className="col-lg-4 text-center">
                        <span id="counter2" />
                        <p>Soluții personalizate</p>
                    </div>
                    <div className="col-lg-4 text-center">
                        <span id="counter3" />
                        <p>Clienți</p>
                    </div>
                </div>
                <br /><br />
            </div>
        </section>
    );
};

export default Facts;
