import React from "react";
import c1 from '../img/about1.jpg';
import c2 from '../img/about2.jpg';
import c3 from '../img/about3.jpg';
import c4 from '../img/about4.jpg';
import c5 from '../img/about5.jpg';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';

const About = () => {
  return (
    <section id="about">
      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <h3>Despre noi</h3>
          <br />
        </header>
        <div className="row">
          <div className="col-md-6" data-aos="fade-up" data-aos-delay={250}>
            <div className="about-col">
              <div className="about-img">
                <Swiper
                  modules={[Autoplay]}
                  speed={800}
                  loop={true}
                  autoplay={{ delay: 1500, disableOnInteraction: false }}
                  slidesPerView={1}
                >
                  <SwiperSlide className="about-slide">
                    <img src={c1} className="img-fluid" alt="" />
                  </SwiperSlide>
                  <SwiperSlide className="about-slide">
                    <img src={c2} className="img-fluid" alt="" />
                  </SwiperSlide>
                  <SwiperSlide className="about-slide">
                    <img src={c3} className="img-fluid" alt="" />
                  </SwiperSlide>
                  <SwiperSlide className="about-slide">
                    <img src={c4} className="img-fluid" alt="" />
                  </SwiperSlide>
                  <SwiperSlide className="about-slide">
                    <img src={c5} className="img-fluid" alt="" />
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
          <div className="col-md-6 aboutContain" data-aos="fade-up" data-aos-delay={100}>
            <div className="about-col">
              <div className="img">
                <div className="icon">
                  <i className="bi bi-people" />
                </div>
              </div>
              <h2 className="title">
              </h2>
              <p>
                O echipă dedicată, cu o experiență cumulată de peste 12 ani în a
                sprijini companiile să-și atingă obiectivele, implementând soluții pentru eficientizarea producției și
                minimizarea costurilor și a timpilor neproductivi.
              </p>
            </div>
          </div>
        </div>
      </div>
      <br />
    </section >
  );
};

export default About;
