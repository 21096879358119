import h1 from "../img/hero-carousel/1.webp";
import h2 from "../img/hero-carousel/2.webp";
import h3 from "../img/hero-carousel/3.webp";
import h4 from "../img/hero-carousel/4.webp";
import h5 from "../img/hero-carousel/5.webp";
import h6 from "../img/hero-carousel/6.webp";


export const heroImages = [
    {
        img: h1,
        alt: ""
    },
    {
        img: h2,
        alt: ""
    },
    {
        img: h3,
        alt: ""
    },
    {
        img: h4,
        alt: ""
    },
    {
        img: h5,
        alt: ""
    },
    {
        img: h6,
        alt: ""
    },
];