import aar1 from "../img/portfolio/aar1.jpg"
import aar2 from "../img/portfolio/aar2.jpg"
import asb1 from "../img/portfolio/asb1.jpg"
import asb2 from "../img/portfolio/asb2.jpg"
import asb3 from "../img/portfolio/asb3.jpg"
import asb4 from "../img/portfolio/asb4.jpg"
import asb5 from "../img/portfolio/asb5.jpg"
import asb6 from "../img/portfolio/asb6.jpeg"
import asfap1 from "../img/portfolio/asfap1.jpg"
import asfap2 from "../img/portfolio/asfap2.jpg"
import asfap3 from "../img/portfolio/asfap3.jpg"
import asfap4 from "../img/portfolio/asfap4.jpg"
import asfap5 from "../img/portfolio/asfap5.jpg"
import ccat1 from "../img/portfolio/ccat1.jpg"
import ccat2 from "../img/portfolio/ccat2.jpg"
import ccat3 from "../img/portfolio/ccat3.jpg"
import ccat4 from "../img/portfolio/ccat4.jpg"
import ctd1 from "../img/portfolio/ctd1.jpg"
import ctd2 from "../img/portfolio/ctd2.jpg"
import ctd3 from "../img/portfolio/ctd3.jpg"
import ecad1 from "../img/portfolio/ecad1.jpg"
import ecad2 from "../img/portfolio/ecad2.jpg"
import ecad3 from "../img/portfolio/ecad3.jpg"
import ecad4 from "../img/portfolio/ecad4.jpg"
import evm1 from "../img/portfolio/evm1.jpg"
import evm2 from "../img/portfolio/evm2.jpg"
import evm3 from "../img/portfolio/evm3.jpg"
import evm4 from "../img/portfolio/evm4.jpg"
import evm5 from "../img/portfolio/evm5.jpg"
import evm6 from "../img/portfolio/evm6.jpg"
import time1 from "../img/portfolio/time1.jpg"
import time2 from "../img/portfolio/time2.jpg"
import time3 from "../img/portfolio/time3.jpg"
import time4 from "../img/portfolio/time4.jpg"
import tfip1 from "../img/portfolio/tfip1.jpg"
import tfip2 from "../img/portfolio/tfip2.jpg"
import tfip3 from "../img/portfolio/tfip3.jpg"
import tfip4 from "../img/portfolio/tfip4.jpg"
import ta1 from "../img/portfolio/ta1.jpeg"
import ta2 from "../img/portfolio/ta2.jpg"
import tp1 from "../img/portfolio/tp1.jpg"
import tp2 from "../img/portfolio/tp2.jpg"
import tp3 from "../img/portfolio/tp3.jpg"
import tp4 from "../img/portfolio/tp4.jpg"

// max 6 images per project

export const projects = [
    {
        img1: aar1,
        img2: aar2,
        alt: "",
        title:"Automat aclanșare rezervă",
        description: "Beneficiar: consumator rezidențial care locuiește în zona în care apar întreruperi repetate a alimentării cu energie electrică de la rețeaua de distribuție națională. Această automatizare gestionează funcționarea autonomă a generatorului, minimizând implicarea din partea beneficiarului și asigurând alimentarea continuă cu energie electrică a consumatorului."
    },
    
    {
        img1: asb1,
        img2: asb2,
        img3: asb3,
        img4: asb4,
        img5: asb5,
        img6: asb6,
        alt: "",
        title:"Automatizare stație betoane",
        description: "Automatizarea a presupus înlocuirea panoului de comandă manual (cu logică secvențială) cu un sistem automat programabil, care asigură prepararea betonului în mod automat, în baza rețetarului pregătit de operator. De asemenea, este asigurată repetabilitatea șarjelor și exploatarea în condiții de siguranță a stației. Șarjele pregătite pot fi apoi evacuate direct în betonieră sau într-un cărucior transportor, care poate fi trimis automat în stațiile de descărcare."
    },
    {
        img1: asfap1,
        img2: asfap2,
        img3: asfap3,
        img4: asfap4,
        img5: asfap5,
        alt: "",
        title:"Automatizare stație filtrare apă potabilă",
        description: "Înlocuirea sistemului de automatizare existent care nu răspundea cerințelor beneficiarului, cu un sistem care asigură filtrarea în condiții optime a apei, ținând cont de consumul instantaneu și de turbiditatea sursei de apă. Sistemul realizează asigurarea stației în cazul întreruperii alimentării cu energie electrică pentru a preveni pierderile de apă și repornirea ei automată. Pe lângă funcțiile existente anterior, s-a implementat monitorizarea presiunii de lucru pentru protejarea filtrelor, monitorizarea consumului instantaneu de apă, verificarea nivelului de îmbâcsire a filtrelor și curățarea lor periodică în mod automat."
    },
    {
        img1: ccat1,
        img2: ccat2,
        img3: ccat3,
        img4: ccat4,
        alt: "",
        title:"Calculator clește automat tubaj",
        description: "Echipament ATEx pentru industria petrolieră folosit în procesul de tubaj. Urmărește înfiletarea corectă a burlanelor monitorizând numărul de rotații, momentul de strângere și asigură protecția la supratorsiune prin eliberarea presiunii de la clește. O dată introduși parametrii optimi pentru filetul burlanului de către operator, calculatorul verifică respectarea acestora. La sfârșitul lucrării operatorul poate genera un raport, pe care sunt prezentate toate graficele de strângere-desfacere a garniturii, evidențiindu-se momentul maxim de strângere și numărul de ture."
    },
    {
        img1: ctd1,
        img2: ctd2,
        img3: ctd3,
        alt: "",
        title:"Comandă Top Drive",
        description: "Pupitru sondor șef ATEx cu touch-panel color și tablou cu controller logic programabil pentru comanda sistemului hidraulic de acționare a instalației de foraj."
    },
    {
        img1: ecad1,
        img2: ecad2,
        img3: ecad3,
        img4: ecad4,
        alt: "",
        title:"ECAD",
        description: "Echipament care extrage valorile de consum postate pe platforma DAMAS, pusă la dispoziție de Transelectrica, și le transmite sub formă de semnal unificat direct unității de comandă a centralelor electrice, și atenționează acustic și vizual operatorul de modificarea survenită."
    },
    {
        img1: evm1,
        img2: evm2,
        img3: evm3,
        img4: evm4,
        img5: evm5,
        img6: evm6,
        alt: "",
        title:"Echipament ventilație mecanică",
        description: "Echipament fabricat din dorința de a veni în sprijinul personalului medical la începutul pandemiei de Covid. Ventilatorul a fost creat pentru a furniza asistenţă de ventilare non-invazivă pentru tratamentul pacienţilor adulţi care suferă de insuficienţă respiratorie. Este un dispozitiv pneumatic comandat de un controller logic programabil și are un touch-panel color independent care asigură interfața cu utilizatorul. Funcționează în trei moduri: CPAP (presiunea este menținută constantă pe toată durata ciclului respirator), VSP (ventilație spontană cu suport de presiune – un mod strict asistat, în care efortul respirator al pacientului este trigger pentru “inspir” și “expir”) și BiPAP (o combinație între cele două moduri anterioare)."
    },
    {
        img1: time1,
        img2: time2,
        img3: time3,
        img4: time4,
        alt: "",
        title:"Tablou încercări motoare electrice",
        description: "Tablou de încercări realizat conform cerințelor beneficiarului, pentru evaluarea parametrilor de funcționare a echipamentelor din service, înainte de a fi predate beneficiarului."
    },
    {
        img1: tfip1,
        img2: tfip2,
        img3: tfip3,
        img4: tfip4,
        alt: "",
        title:"Tambur furtun înaltă presiune",
        description: "Tambur metalic care realizează rularea-derularea automată a furtunului, măsurând cu precizie lungimea derulată și dispunând totodată de limitarea forței de tragere la rulare."
    },
    {
        img1: ta1,
        img2: ta2,
        alt: "",
        title:"Telecomandă automacara",
        description: "Înlocuirea sistemului de comandă la distanță și adăugarea unui modul de limitare a sarcinii, configurabil de către operator."
    },
    {
        img1: tp1,
        img2: tp2,
        img3: tp3,
        img4: tp4,
        alt: "",
        title:"Tester presiune",
        description: "Dispozitiv de încercare a reperelor din plastic produse prin injectare, cu testarea și înregistrarea presiunii de funcționare și cedare."
    },
];