import React, { useState } from "react";

const BackToTop = () => {
  const [active, setActive] = useState(false);

  const toggleBacktotop = () => {
    if (window.scrollY >= 100) {
      setActive(true);
    } else {
      setActive(false);
    }
  };
  window.addEventListener("scroll", toggleBacktotop);

  return (
    <>
      <a
        href="#hero"
        className={`back-to-top d-flex align-items-center justify-content-center ${active ? "active" : ""
          }`}
      >
        <i className="bi bi-arrow-up-short" />
      </a>
    </>
  );
};

export default BackToTop;
