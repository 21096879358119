import s1 from "../img/svg/stethoscope-solid.svg";
import s2 from "../img/svg/tools-solid.svg";
import s3 from "../img/svg/pencil-ruler-solid.svg";
import s4 from "../img/svg/users-cog-solid.svg";
import s5 from "../img/svg/cogs-solid.svg";
import s6 from "../img/svg/fingerprint-solid.svg";


export const services = [
    {
        img: s5,
        alt: "",
        delay: 100,
        name: "Automatizări",
        message: "Proiectarea și implementarea de automatizări sisteme electrice și pneumatice"
    },
    {
        img: s2,
        alt: "",
        delay: 200,
        name: "Depanare",
        message: "Servicii de diagnosticare pentru utilaje industriale, echipamente electrice și electronice, sisteme pneumatice. Remediere, reparații și testare, atât la sediul nostru cât și pe teren, în funcție de situația impusă"
    },
    {
        img: s4,
        alt: "",
        delay: 300,
        name: "Mentenanță",
        message: "Inspecții și revizii periodice, recomandare și implementare de sisteme de mentenanță preventivă, predictivă și corectivă"
    },
    {
        img: s3,
        alt: "",
        delay: 300,
        name: "Retehnologizare",
        message: "Servicii de modernizare a echipamentelor industriale vechi, care funcționează manual sau pe logică secvențială"
    },
    {
        img: s1,
        alt: "",
        delay: 400,
        name: "Consultanță tehnică",
        message: "Servicii de consultanță tehnică în management de proiect, în domeniile în care există procese automatizate"
    },
    {
        img: s6,
        alt: "",
        delay: 500,
        name: "Prototipare",
        message: "Proiectarea și realizarea in-house a reperelor pentru sistemele de automatizare"
    },
];