import React, { useState } from "react";
import { projects } from "../data/projects";
import MoreInfo from "./MoreInfo";
import { Navigation, Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Portfolio = () => {
  const [show, setShow] = useState(false);
  const [tempdata, setTempdata] = useState([]);

  const getData = (img1, img2, img3, img4, img5, img6, title, desc) => {
    let tempData = [img1, img2, img3, img4, img5, img6, title, desc];
    setTempdata(project => [1, ...tempData])
    return setShow(true);
  }

  return (
    <>
      <section id="portfolio" className="section-bg">
        <div className="container" data-aos="fade-up">
          <header className="section-header">
            <h3 className="section-title">Portofoliu</h3>
            <br />
          </header>
          <Swiper
            modules={[Autoplay, Navigation, Pagination]}
            speed={800}
            loop={true}
            autoplay={{ delay: 1500, disableOnInteraction: false }}
            breakpoints={{
              220: {
                slidesPerView: 1,
                spaceBetween: 0
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 20
              }

            }}
            navigation
            pagination={{ clickable: true }}
          >
            {projects.map((project, i) =>
              <SwiperSlide key={(project, i)} className="portfolio-item">
                <div className="portfolio-wrap">
                  <figure>
                    <img
                      onClick={() => getData(project.img1, project.img2, project.img3, project.img4, project.img5, project.img6, project.title, project.description)}
                      src={project.img1}
                      className="img-fluid"
                      alt=""
                    />
                  </figure>
                  <div className="portfolio-info">
                    <p onClick={() => getData(project.img1, project.img2, project.img3, project.img4, project.img5, project.img6, project.title, project.description)}>
                      {project.title}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            )}
          </Swiper>
        </div>
      </section>
      {
        show === true ? <MoreInfo img1={tempdata[1]} img2={tempdata[2]} img3={tempdata[3]} img4={tempdata[4]} img5={tempdata[5]} img6={tempdata[6]} title={tempdata[7]} description={tempdata[8]} hide={() => setShow(false)} /> : ''
      }
    </>
  );
};

export default Portfolio;
