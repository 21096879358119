import React from "react";
import { services } from "../data/services";

const Services = () => {
  return (
    <section id="services">
      <div className="container" data-aos="fade-up">
        <header className="section-header wow fadeInUp">
          <h3>Servicii</h3>
          <p>
            Oferim companiilor soluții bazate pe cele mai recente tehnologii
            pentru a scădea costul de operare și întreținere.
          </p>
        </header>
        <div className="row" >
          {services.map((service, i) =>
            <div
              key={(service, i)}
              className="col-lg-4 col-md-6 box"
              data-aos="fade-up"
              data-aos-delay={service.delay}
            >
              <div className="icon">
                <img
                  src={service.img}
                  className="serviceSVG"
                  alt="svg"
                />
              </div>
              <h4 className="title">
                <div>{service.name}</div>
              </h4>
              <p className="description">
                {service.message}
              </p>
            </div>
          )}
        </div>
      </div>
      <br />
    </section>
  );
};

export default Services;
